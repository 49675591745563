import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translateText from './translateService'; // Import the translation service

// Translation files
const resources = {
    en: {
        translation: {
          "thanks": "Thanks for subscribing",
          "we_notify": "We will notify you every week",
        },
      },
//   en: {
//     translation: {
//       "Thanks for subscribing": "Thanks for subscribing",
//       "We will notify you every week": "We will notify you every week",
//       // Add other translations here
//     },
//   },
//   de: {
//     translation: {
//       "Thanks for subscribing": "Danke fürs Abonnieren",
//       "We will notify you every week": "Wir werden Sie jede Woche benachrichtigen",
//       // Add other translations here
//     },
//   },
//   fr: {
//     translation: {
//       "Thanks for subscribing": "Merci de vous être abonné",
//       "We will notify you every week": "Nous vous informerons chaque semaine",
//       // Add other translations here
//     },
//   },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

// Function to dynamically add missing translations
i18n.on('languageChanged', async (lng) => {
  if (!resources[lng]) {
    resources[lng] = { translation: {} };
  }

  const enTranslations = resources.en.translation;
  for (const key in enTranslations) {
    if (!resources[lng].translation[key]) {
      const translatedText = await translateText(enTranslations[key], lng);
      resources[lng].translation[key] = translatedText;
      i18n.addResource(lng, 'translation', key, translatedText);
    }
  }
});

export default i18n;
