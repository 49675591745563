import React, { useState, useEffect } from 'react';
import styles from "./landpage.module.css";

const CustomSelect = () => {
    const [isFixed, setFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const quarterHeight = 650;

            if (window.scrollY > quarterHeight) {
                setFixed(true);
            } else {
                setFixed(false);
            }
        };

        // Add event listener for scroll events
        window.addEventListener("scroll", handleScroll);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


  useEffect(() => {
    const customSelects = document.getElementsByClassName(styles.custom_select);

    const closeAllSelect = (elmnt) => {
      /* A function that will close all select boxes in the document,
      except the current select box: */
      let x, y, i, xl, yl, arrNo = [];
      x = document.getElementsByClassName(styles.select_items);
      y = document.getElementsByClassName(styles.select_selected);
      xl = x.length;
      yl = y.length;
      for (i = 0; i < yl; i++) {
        if (elmnt === y[i]) {
          arrNo.push(i);
        } else {
          y[i].classList.remove(styles.select_arrow_active);
        }
      }
      for (i = 0; i < xl; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add(styles.select_hide);
        }
      }
    };

    document.addEventListener('click', closeAllSelect);

    Array.from(customSelects).forEach((select) => {
      const selElmnt = select.getElementsByTagName('select')[0];
      const ll = selElmnt.length;

      const a = document.createElement('DIV');
      a.setAttribute('class', styles.select_selected);
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      select.appendChild(a);

      const b = document.createElement('DIV');
      b.setAttribute('class', `${styles.select_items} ${styles.select_hide}`);

      for (let j = 1; j < ll; j++) {
        const c = document.createElement('DIV');
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener('click', function (e) {
          /* When an item is clicked, update the original select box,
          and the selected item: */
          let y, i, k, s, h, sl, yl;
          s = this.parentNode.parentNode.getElementsByTagName('select')[0];
          sl = s.length;
          h = this.parentNode.previousSibling;
          for (i = 0; i < sl; i++) {
            if (s.options[i].innerHTML === this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName(styles.same_as_selected);
              yl = y.length;
              for (k = 0; k < yl; k++) {
                y[k].removeAttribute('class');
              }
              this.setAttribute('class', styles.same_as_selected);
              break;
            }
          }
          h.click();
        });
        b.appendChild(c);
      }

      select.appendChild(b);

      a.addEventListener('click', function (e) {
        /* When the select box is clicked, close any other select boxes,
        and open/close the current select box: */
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle(styles.select_hide);
        this.classList.toggle(styles.select_arrow_active);
      });
    });

    return () => {
      // Cleanup event listener when the component unmounts
      document.removeEventListener('click', closeAllSelect);
    };
  }, []);

  return (
    <div className={`${styles.custom_select} ${isFixed ? styles.change_bg : ''}`}>
      {/* Your select options here */}
      <select>
        <option value="0">English</option>
        <option value="0">English</option>
        <option value="1">Mandarin Chinese</option>
        {/* <option value="1">Spanish</option> 
        <option value="2">French</option>
        <option value="3">German</option>
        <option value="4">Chinese</option>
        <option value="5">Russian</option>
        <option value="6">Hindi</option>
        <option value="7">Mandarin Chinese</option>
        <option value="8">Portuguese</option>
        <option value="9">Japanese</option>
        <option value="10">Italian</option>
        <option value="11">Turkish</option>
        <option value="12">Korean</option>
        <option value="13">Yoruba</option>
        <option value="14">Igbo</option>
        <option value="15">Hausa</option>
        <option value="16">Zulu</option>
        <option value="17">Swahili</option>
        <option value="18">Afrikaans</option> */}
      </select>
    </div>
  );
};

export default CustomSelect;
