import { useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Popover } from '@mui/material';
import { useLanguage } from '../../../theme/languageContext'; // Import the useLanguage hook
import bus from "src/bus"
import axiosInstance from "src/axiosInstance"

const LANGS = [
  { value: 'en', label: 'English', icon: '/assets/icons/ic_flag_en.svg' },
  { value: 'zh', label: 'Mandarin Chinese', icon: '/assets/icons/ic_flag_cny.svg' },
];

export default function LanguagePopover() {
  const [open, setOpen] = useState(null);
  const { language, changeLanguage } = useLanguage();
  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('userDetails')));
  const [langStatus, setLangStatus] = useState(JSON.parse(localStorage.getItem('lang_status')));
  const [count, setCount] = useState(0);
  const authUser = JSON.parse(localStorage.getItem('auth'));
  const apiUrl = process.env.REACT_APP_NAME;

  const handleChangeLanguage = (newLanguage) => {
    changeLanguage(newLanguage);
    setOpen(null);
  };

  const getUserDetails = async () => {
    // console.log('getUserDetails called');
    try {
      const response = await axiosInstance.get(`/accounts/user?productName=${apiUrl}`);
      const data = response.data.data;

      if (response) {
        // console.log('success res!', data);
        setUserDetails(data);

        if ((data !== null) && (data.data.language)) {
          // console.log('data exists');

          const LangKey = data?.data?.language;
          // console.log('LangKey :', LangKey);

          const matchedLang = LANGS.find(Lang => Lang.label === LangKey);
          // console.log('matchedLang:', matchedLang);
          changeLanguage(matchedLang.value);
          setTimeout(() => {
            localStorage.setItem('lang_status', JSON.stringify(true));
          }, 500);
        }
        
        if (langStatus === null || langStatus === undefined) {
          // console.log('langStatus is NULL', 'userdetails:', data, data?.data?.language);
          if ((data !== null) && (data.data.language)) {
            // console.log('data exists');

            const LangKey = data?.data?.language;
            // console.log('LangKey :', LangKey);

            const matchedLang = LANGS.find(Lang => Lang.label === LangKey);
            // console.log('matchedLang:', matchedLang);
            changeLanguage(matchedLang.value);
            setTimeout(() => {
              localStorage.setItem('lang_status', JSON.stringify(true));
            }, 500);
          }
        } else {
          const storedLanguage = localStorage.getItem('selectedLanguage');
          if (storedLanguage) {
            changeLanguage(storedLanguage);
            // console.log('storedLanguage', storedLanguage);
          }
        }
        localStorage.setItem('userDetails', JSON.stringify(data));
      }
    } catch (err) {
      console.error('Err: ', err);
    }
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    getUserDetails();
    // if (authUser && authUser.account_id && (langStatus === null || langStatus === undefined)) {
    // }

    const activateDefaultLang = (val) => {
      setTimeout(() => {
        if (val) {
          setLangStatus(JSON.parse(localStorage.getItem('lang_status')));
          setUserDetails(JSON.parse(localStorage.getItem('userDetails')));
          // console.log('langStatus in count:', langStatus);

          if (langStatus === null || langStatus === undefined) {
            // console.log('langStatus is NULL', 'userdetails:', userDetails, userDetails?.data?.language);
            if ((userDetails !== null) && (userDetails.data.language)) {
              // console.log('userDetails exists');

              const LangKey = userDetails?.data?.language;
              // console.log('LangKey :', LangKey);

              const matchedLang = LANGS.find(Lang => Lang.label === LangKey);
              // console.log('matchedLang:', matchedLang);
              changeLanguage(matchedLang.value);
              setTimeout(() => {
                localStorage.setItem('lang_status', JSON.stringify(true));
              }, 500);
            }
          } else {
            const storedLanguage = localStorage.getItem('selectedLanguage');
            if (storedLanguage) {
              changeLanguage(storedLanguage);
              // console.log('storedLanguage', storedLanguage);
            }
          }
        }
      }, 1000);
    };

    bus.on("lang_status", activateDefaultLang);

    return () => {
      bus.off("lang_status", activateDefaultLang);
    };
  }, []);

  return (
    <>
      <div onClick={handleOpen} className="d-flex align-items-center cursor-pointer">
        <span>
          <IconButton sx={{ padding: 0, width: 40, height: 40, ...(open && { bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity) }) }}>
            <img width={20} src={LANGS.find((lang) => lang.value === language)?.icon} alt={LANGS.find((lang) => lang.value === language)?.label} />
          </IconButton>
          {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3487_31412)">
                        <path d="M12.7066 12.4467L11.2799 9.6001L9.85327 12.4467" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10.1133 11.9399H12.46" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.28 14.6664C9.41331 14.6664 7.89331 13.153 7.89331 11.2797C7.89331 9.41304 9.40665 7.89307 11.28 7.89307C13.1466 7.89307 14.6666 9.40637 14.6666 11.2797C14.6666 13.153 13.1533 14.6664 11.28 14.6664Z" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M3.34659 1.3335H5.95992C7.33992 1.3335 8.00659 2.00018 7.97326 3.34684V5.96016C8.00659 7.34016 7.33992 8.00684 5.95992 7.97351H3.34659C1.99992 8.00018 1.33325 7.33349 1.33325 5.95349V3.34017C1.33325 2.00017 1.99992 1.3335 3.34659 1.3335Z" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6.00671 3.8999H3.30005" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.64673 3.44678V3.90011" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.32655 3.89307C5.32655 5.05973 4.41321 6.00639 3.29321 6.00639" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6.00652 6.00684C5.51985 6.00684 5.07986 5.74683 4.77319 5.3335" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M1.33325 10C1.33325 12.58 3.41992 14.6667 5.99992 14.6667L5.29992 13.5" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M14.6667 6.00016C14.6667 3.42016 12.58 1.3335 10 1.3335L10.7 2.50016" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3487_31412">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg> */}
        </span>
        <div
          className="text-primary d-flex justify-content-between align-items-center rounded"
          style={{ padding: '3px 0px', width: '90px' }}
        >
          <span
            style={{
              color: "#222222",
              fontWeight: "500",
              fontSize: '12px',
              whiteSpace: 'nowrap',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              wordWrap: 'normal',
              wordBreak: 'keep-all',
              width: '100px',
            }}
          >
            {LANGS.find((lang) => lang.value === language)?.label}
          </span>
          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.29995 4.22949L5.58328 6.94616C5.26245 7.26699 4.73745 7.26699 4.41662 6.94616L1.69995 4.22949" stroke="#111827" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      </div>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem key={option.value} selected={option.value === language} onClick={() => handleChangeLanguage(option.value)}>
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
}