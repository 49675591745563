import React, { createContext, useState, useContext, useEffect } from 'react';
import i18n from './i18n'; // Import the i18n configuration
import translateText from './translateService'; // Import the translation service

const LanguageContext = createContext({
  language: 'en',
  changeLanguage: () => {}
});

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');

  const changeLanguage = async (newLanguage) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('selectedLanguage', newLanguage);

    // Handle translation of missing keys dynamically
    const enTranslations = i18n.getResourceBundle('en', 'translation');
    const currentTranslations = i18n.getResourceBundle(newLanguage, 'translation') || {};

    for (const key in enTranslations) {
      if (!currentTranslations[key]) {
        const translatedText = await translateText(enTranslations[key], newLanguage);
        i18n.addResource(newLanguage, 'translation', key, translatedText);
      }
    }
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage && storedLanguage !== language) {
      changeLanguage(storedLanguage);
    }
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};






// import React, { createContext, useState, useContext, useEffect } from 'react';
// import i18n from './i18n'; // Import the i18n configuration

// const LanguageContext = createContext({
//   language: 'en',
//   changeLanguage: () => {}
// });

// export const LanguageProvider = ({ children }) => {
//   const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');

//   const changeLanguage = (newLanguage) => {
//     setLanguage(newLanguage);
//     i18n.changeLanguage(newLanguage);
//     localStorage.setItem('selectedLanguage', newLanguage);
//   };

//   useEffect(() => {
//     const storedLanguage = localStorage.getItem('selectedLanguage');
//     if (storedLanguage && storedLanguage !== language) {
//       setLanguage(storedLanguage);
//       i18n.changeLanguage(storedLanguage);
//     }
//   }, [language]);

//   return (
//     <LanguageContext.Provider value={{ language, changeLanguage }}>
//       {children}
//     </LanguageContext.Provider>
//   );
// };

// export const useLanguage = () => {
//   return useContext(LanguageContext);
// };
