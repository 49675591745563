import React, { useState, useEffect } from "react";
import { Link } from "@mui/material";
import styles from "./pages/Dashboard/landpage.module.css";
import "./Global.css";
import CustomSelect from "./pages/Dashboard/LanguageSelect";
import { Link as RouterLink } from 'react-router-dom';

export default function TopNavbar() {
    const [isHamburgerActive] = useState(false);
    const [isFixed, setFixed] = useState(false);
    const [isShowFixed, setShowFixed] = useState(false);


    useEffect(() => {
        const handleScroll = () => {
            // Calculate one-quarter of the page height
            const quarterHeight = 650;
            const quarterShowHeight = 700;
            // Check if the scroll position is beyond one-quarter of the page height
            if (window.scrollY > quarterHeight) {
                setFixed(true);
            } else {
                setFixed(false);
            }
            if (window.scrollY > quarterShowHeight) {
                setShowFixed(true);
            } else {
                setShowFixed(false);
            }
        };

        // Add event listener for scroll events
        window.addEventListener("scroll", handleScroll);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (
        <>
            <header id="header" className={`${isFixed ? "fixed" : ""} ${isShowFixed ? 'scrolled' : ''}`}>
                <div className={`container ${styles.nav_container} ${isHamburgerActive ? "active" : ""} d-flex justify-content-between align-items-center`}>
                    <div className={`${styles.logo_box} ${isFixed ? "sat_image" : ""} logo_box mt-3 mr-auto`}>
                        <Link to="/landpage" component={RouterLink}>
                            <img
                                src="/assets/logo.png"
                                className={`${styles.header_img} header_img`}
                                alt="Launchpad Logo"
                            />
                        </Link>
                    </div>

                    <div className="navbar px-0 pt-0" id="nav-bar2">
                        <ul
                            className={`nav_menu ${isHamburgerActive ? "active" : ""
                                }`}
                        >
                            <li className={`${styles.nav_item} nav_item nav-item d-none d-sm-inline`}>
                            <svg width="27" height="27" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={` ${isFixed ? "sat_image" : ""} mb-1`}>
                                    <g clipPath="url(#clip0_675_132)">
                                        <path d="M12.7067 12.4467L11.28 9.60004L9.85333 12.4467" stroke="#D1D5DB" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.1133 11.94H12.46" stroke="#D1D5DB" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11.28 14.6667C9.41331 14.6667 7.89331 13.1533 7.89331 11.28C7.89331 9.41334 9.40665 7.89337 11.28 7.89337C13.1466 7.89337 14.6666 9.40668 14.6666 11.28C14.6666 13.1533 13.1533 14.6667 11.28 14.6667Z" stroke="#D1D5DB" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M3.34668 1.33331H5.96001C7.34001 1.33331 8.00668 1.99999 7.97335 3.34666V5.95998C8.00668 7.33998 7.34001 8.00666 5.96001 7.97333H3.34668C2.00001 7.99999 1.33334 7.33331 1.33334 5.95331V3.33999C1.33334 1.99999 2.00001 1.33331 3.34668 1.33331Z" stroke="#D1D5DB" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.00659 3.89996H3.29993" stroke="#D1D5DB" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4.64661 3.44666V3.89998" stroke="#D1D5DB" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.32655 3.89337C5.32655 5.06004 4.41321 6.00669 3.29321 6.00669" stroke="#D1D5DB" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.00652 6.00665C5.51985 6.00665 5.07986 5.74665 4.77319 5.33331" stroke="#D1D5DB" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M1.33331 10C1.33331 12.58 3.41998 14.6667 5.99998 14.6667L5.29998 13.5" stroke="#D1D5DB" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14.6667 5.99998C14.6667 3.41998 12.58 1.33331 10 1.33331L10.7 2.49998" stroke="#D1D5DB" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_675_132">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </li>
                            <li className={`${styles.nav_item} nav_item nav-item`}>
                                <CustomSelect />
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </>
    );
}
