import { Navigate, useRoutes } from "react-router-dom";
import React from 'react';

// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
import LoginPage from "./pages/Auth/auth/LoginPage";
import UsePasswordLogin from "./pages/Auth/auth/UsePasswordLogin";
import MagicLink from "./pages/Auth/auth/MagicLink";
import SignupPage from "./pages/Auth/auth/SignupPage";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import PaymentCheckout from "./pages/Auth/PaymentCheckout";
import TWOFAAuth from "./pages/Auth/2FAAuth";
import New2FAVerification from "./pages/Auth/New2FAVerification";
import OtpScreen from "./pages/Auth/OtpScreen";
import VerifyEmail from "./pages/Auth/VerifyEmail";
import ResetPassword from "./pages/Auth/ResetPassword";
import Page404 from "./pages/Page404";
import DashboardIndex from "./pages/Dashboard/Index";
import AppHome from "./pages/Home/index";
import AKPU from './pages/Onboarding/KYCSteps';


// PAYMENT
import PaymentLinks from "./pages/Payment/PaymentLinks";
import PaymentInvoice from "./pages/Payment/Invoice";
// import PaymentTransfers from "./pages/Payment/Transfers";
import PaymentPayroll from "./pages/Payment/Payroll";
import PaymentChargebacks from "./pages/Payment/Chargebacks";
import PaymentChargebackDetails from "./pages/Payment/chargebacks_comp/ChargebackDetails";
import PaymentRefunds from "./pages/Payment/Refunds";
import SingleCashPayment from "./pages/Payment/other_components/SingleCashPayment";
import BulkCashPayment from "./pages/Payment/other_components/BulkCashPayment";
import EditInvoice from "./pages/Payment/Invoice_comp/EditInvoice";
import PreviewInvoice from "./pages/Payment/Invoice_comp/PreviewInvoice";
import InvoicePrevFromEmail from "./pages/Payment/Invoice_comp/InvoicePrevFromEmail";
import PaymentLinkPreview from "./pages/Payment/paymentlink_comp/PreviewContent";
import CreateInvoice from "./pages/Payment/Invoice_comp/CreateInvoice";
import PLSinglePayment from "./pages/Payment/paymentlink_comp/PLSingle";
import PLBulkPayment from "./pages/Payment/paymentlink_comp/PLBulk";
import CreatePaymentLink from "./pages/Payment/paymentlink_comp/CreatePL";
import LogRefunds from "./pages/Payment/refunds_comp/LogRefunds";

// CUSTOMER
import CustomerOverview from "./pages/Customers/Index";
import CustomerDetails from "./pages/Customers/customer_comp/CustomerDetails";
import CustomerGroupStat from "./pages/Customers/customer_comp/CustomerGroupStat";
import CreateGroup from "./pages/Customers/customer_comp/CreateGroup";

// MESSAGING
import Messaging from "./pages/Messaging/Messaging";
import SendMessage from "./pages/Messaging/SendMessage";
import SingleMessaging from "./pages/Messaging/components/SingleMessaging";
import BulkMessaging from "./pages/Messaging/components/BulkMessaging";

// OTP
import ConfigureOTP from "./pages/OTP/ConfigureOTP";
import OTPLogs from "./pages/OTP/OTPLogs";

// BILLS
import Airtime from "./pages/Bills/Airtime";
import Data from "./pages/Bills/Data";

// LEDGER MANAGEMENT
import LedgerManagementAccounts from "./pages/Ledger-Management/LedgerAccounts";
import LedgerDetails from "./pages/Ledger-Management/LedgerDetails";
import AccountDetails from "./pages/Ledger-Management/AccountDetails";
import NewTransaction from "./pages/Ledger-Management/NewTransaction";
import NewAccount from "./pages/Ledger-Management/NewAccount";
import EditAccount from "./pages/Ledger-Management/EditAccount";
import EditTransaction from "./pages/Ledger-Management/EditTransaction";
import JournalEntries from "./pages/Ledger-Management/JournalEntries";
import SalesLedger from "./pages/Ledger-Management/SalesLedger";
import AddJournalEntries from "./pages/Ledger-Management/AddJournalEntries";
import LedgerEntries from "./pages/Ledger-Management/LedgerEntries";
import EditLedgerEntries from "./pages/Ledger-Management/EditLedgerEntries";

// WALLET
import WalletPage from "./pages/Wallet/Wallet";
import ConvertFunds from "./pages/Wallet/ConvertFunds";
import MoreTransactions from "./pages/Wallet/MoreTransactions";
import TransferToBank from "./pages/Wallet/components/TransferToBank";
import MakeBulkPayment from "./pages/Wallet/components/MakeBulkPayment";
import WalletOTP from "./pages/Wallet/components/WalletOTP";
import OnePointTrf from "./pages/Wallet/components/OnePointTrf";
import CreateWallet from "./pages/Wallet/components/CreateWallet";
import WithdrawFunds from "./pages/Wallet/components/WithdrawFunds";
import FundWallet from "./pages/Wallet/components/FundWallet";
import WalletTopUps from "./pages/Wallet/TopUps/Index";
import WalletTransfers from "./pages/Wallet/Transfers/Index";
import WalletFeesPage from "./pages/Wallet/Fees/Index";
import { useAuth } from "./hooks/useAuth";
import withAuth from "./auth.hoc.js";

// WALLET
import UserSettings from "./pages/UserSettings/Index";
import CustomRole from "./pages/UserSettings/components/CustomRole";
import InviteTeam from "./pages/UserSettings/components/InviteTeam";
// ----------------------------------------------------------------------

  const openRoutes = [
    "login",
    "forgot-password",
    "verify-email",
    "signup",
    "landpage",
    "reset-password",
    "use-password",
  ];

// Auth components
const AuthDashboardLayout = withAuth(DashboardLayout, openRoutes);
const AuthAppHome = withAuth(AppHome, openRoutes);
const AuthWalletTransfers = withAuth(WalletTransfers, openRoutes);

const AuthTransferToBank = withAuth(TransferToBank, openRoutes);
const AuthMakeBulkPayment = withAuth(MakeBulkPayment, openRoutes);
const AuthPaymentInvoice = withAuth(PaymentInvoice, openRoutes);
const AuthPaymentLinks = withAuth(PaymentLinks, openRoutes);
const AuthPaymentPayroll = withAuth(PaymentPayroll, openRoutes);
const AuthPaymentChargebacks = withAuth(PaymentChargebacks, openRoutes);
const AuthPaymentChargebackDetails = withAuth(PaymentChargebackDetails, openRoutes);
const AuthPaymentRefunds = withAuth(PaymentRefunds, openRoutes);
const AuthSingleCashPayment = withAuth(SingleCashPayment, openRoutes);
const AuthBulkCashPayment = withAuth(BulkCashPayment, openRoutes);
const AuthEditInvoice = withAuth(EditInvoice, openRoutes);
const AuthCreateInvoice = withAuth(CreateInvoice, openRoutes);
const AuthCreatePaymentLink = withAuth(CreatePaymentLink, openRoutes);
const AuthPLSinglePayment = withAuth(PLSinglePayment, openRoutes);
const AuthPLBulkPayment = withAuth(PLBulkPayment, openRoutes);
const AuthLogRefunds = withAuth(LogRefunds, openRoutes);
const AuthCustomerOverview = withAuth(CustomerOverview, openRoutes);
const AuthCustomerGroupStat = withAuth(CustomerGroupStat, openRoutes);
const AuthCustomerDetails = withAuth(CustomerDetails, openRoutes);
const AuthCreateGroup = withAuth(CreateGroup, openRoutes);
const AuthLedgerManagementAccounts = withAuth(LedgerManagementAccounts, openRoutes);
const AuthJournalEntries = withAuth(JournalEntries, openRoutes);
const AuthLedgerDetails = withAuth(LedgerDetails, openRoutes);
const AuthAccountDetails = withAuth(AccountDetails, openRoutes);
const AuthNewTransaction = withAuth(NewTransaction, openRoutes);
const AuthNewAccount = withAuth(NewAccount, openRoutes);
const AuthEditAccount = withAuth(EditAccount, openRoutes);
const AuthEditTransaction = withAuth(EditTransaction, openRoutes);
const AuthLedgerEntries = withAuth(LedgerEntries, openRoutes);
const AuthEditLedgerEntries = withAuth(EditLedgerEntries, openRoutes);
const AuthSalesLedger = withAuth(SalesLedger, openRoutes);
const AuthAddJournalEntries = withAuth(AddJournalEntries, openRoutes);
const AuthWalletOTP = withAuth(WalletOTP, openRoutes);
const AuthOnePointTrf = withAuth(OnePointTrf, openRoutes);
const AuthCreateWallet = withAuth(CreateWallet, openRoutes);
const AuthWithdrawFunds = withAuth(WithdrawFunds, openRoutes);
const AuthFundWallet = withAuth(FundWallet, openRoutes);
const AuthWalletTopUps = withAuth(WalletTopUps, openRoutes);
const AuthWalletFeesPage = withAuth(WalletFeesPage, openRoutes);
const AuthConvertFunds = withAuth(ConvertFunds, openRoutes);
const AuthMoreTransactions = withAuth(MoreTransactions, openRoutes);
const AuthWalletPage = withAuth(WalletPage, openRoutes);
const AuthUserSettings = withAuth(UserSettings, openRoutes);
const AuthCustomRole = withAuth(CustomRole, openRoutes);
const AuthInviteTeam = withAuth(InviteTeam, openRoutes);
const AuthMagicLink = withAuth(MagicLink, openRoutes);
const AuthNew2FAVerification = withAuth(New2FAVerification, openRoutes);
const AuthKYCSteps = withAuth(AKPU, openRoutes);

export default function Router() {
  const { data } = useAuth();
  const isLoggedIn = Object.keys(data).length > 0;

  const routes = useRoutes([
    {
      path: "/app",
      element: isLoggedIn ? (
        <AuthDashboardLayout />
      ) : (
        <Navigate to="/login" />
      ),
      // element: <DashboardLayout />,
      children: [
        { 
          element: <Navigate to="/app/overview" />, 
          index: true 
        },
        // { path: 'overview', element: <AppHome /> },
        {
          path: "overview",
          element: isLoggedIn ? (
            <AuthAppHome />
          ) : (
            <Navigate to="/login" />
          ),
          // children: [
          //   { path: "", element: <AppHome /> },
          // ],
        },
        {
          path: "start-tour",
          element: isLoggedIn ? (
            <AuthAppHome />
          ) : (
            <Navigate to="/login" />
          ),
        },

        // PAYMENT
        {
          path: "payment",
          element: isLoggedIn ? (
            <AuthWalletTransfers />
          ) : (
            <Navigate to="/login" />
          ),
          children: [
            {
              path: "transfers",
              element: <AuthWalletTransfers />,
              index: true,
            },
          ],
        },
        {
          path: "payment/transfers/single",
          element: isLoggedIn ? (
            <AuthTransferToBank />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/transfers/bulk",
          element: isLoggedIn ? (
            <AuthMakeBulkPayment />
          ) : (
            <Navigate to="/login" />
          ),
        },

        {
          path: "payment/invoice",
          element: isLoggedIn ? (
            <AuthPaymentInvoice />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/payment-links",
          element: isLoggedIn ? (
            <AuthPaymentLinks />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/payroll",
          element: isLoggedIn ? (
            <AuthPaymentPayroll />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/chargebacks",
          element: isLoggedIn ? (
              <AuthPaymentChargebacks />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/chargebacks/details",
          element: isLoggedIn ? (
              <AuthPaymentChargebackDetails />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/refunds",
          element: isLoggedIn ? (
              <AuthPaymentRefunds />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/single-cash",
          element: isLoggedIn ? (
              <AuthSingleCashPayment />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/bulk-cash",
          element: isLoggedIn ? (
              <AuthBulkCashPayment />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/invoice/edit",
          element: isLoggedIn ? (
              <AuthEditInvoice />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/invoice/create",
          element: isLoggedIn ? (
              <AuthCreateInvoice />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/payment-links/create",
          element: isLoggedIn ? (
              <AuthCreatePaymentLink />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/payment-links/create-single",
          element: isLoggedIn ? (
              <AuthPLSinglePayment />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/payment-links/create-bulk",
          element: isLoggedIn ? (
              <AuthPLBulkPayment />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "payment/refunds/log-refund",
          element: isLoggedIn ? (
              <AuthLogRefunds />
          ) : (
            <Navigate to="/login" />
          ),
        },

        // CUSTOMERS
        {
          path: "customer",
          element: isLoggedIn ? (
            <AuthCustomerOverview />
          ) : (
            <Navigate to="/login" />
          ),
          children: [
            {
              path: "all",
              element: <AuthCustomerOverview />,
              index: true,
            },
          ],
        },
        // {
        //   path: "customer/customer-statistics",
        //   element: isLoggedIn ? (
        //       <AuthCustomerGroupStat />
        //   ) : (
        //     <Navigate to="/login" />
        //   ),
        // },
        {
          path: "customer/customer-details",
          element: isLoggedIn ? (
              <AuthCustomerDetails />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "customer/create-new-group",
          element: isLoggedIn ? (
              <AuthCreateGroup />
          ) : (
            <Navigate to="/login" />
          ),
        },

        // MESSAGING
        { path: "messaging/sender-id", element: <Messaging /> },
        { path: "messaging/send-message", element: <SendMessage /> },
        { path: "messaging/single-messaging", element: <SingleMessaging /> },
        { path: "messaging/bulk-messaging", element: <BulkMessaging /> },

        // OTP
        { path: "otp/configure-otp", element: <ConfigureOTP /> },
        { path: "otp/otp-logs", element: <OTPLogs /> },

        // BILLS
        {
          path: "bills",
          element: <Airtime />,
          children: [
            { path: "airtime", element: <Airtime /> },
            { path: "data", element: <Data /> },
          ],
        },

        // LEDGER MANAGEMENT
        {
          path: "ledger-management",
          element: isLoggedIn ? (
              <AuthLedgerManagementAccounts />
          ) : (
            <Navigate to="/login" />
          ),
          children: [
            {
              path: "ledger-management",
              element: <AuthLedgerManagementAccounts />,
              index: true,
            },
          ],
        },

        {
          path: "ledger-management/journal-entries",
          element: isLoggedIn ? (
              <AuthJournalEntries />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "ledger-management/ledger-details",
          element: isLoggedIn ? (
              <AuthLedgerDetails />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "ledger-management/ledger-details/accounts",
          element: isLoggedIn ? (
              <AuthAccountDetails />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "ledger-management/new-transaction",
          element: isLoggedIn ? (
              <AuthNewTransaction />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "ledger-management/new-account",
          element: isLoggedIn ? (
              <AuthNewAccount />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "ledger-management/edit-account",
          element: isLoggedIn ? (
              <AuthEditAccount />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "ledger-management/edit-transaction",
          element: isLoggedIn ? (
              <AuthEditTransaction />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "ledger-management/ledger-entries",
          element: isLoggedIn ? (
              <AuthLedgerEntries />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "ledger-management/ledger-entries/edit",
          element: isLoggedIn ? (
              <AuthEditLedgerEntries />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "ledger-management/sales-ledger",
          element: isLoggedIn ? (
              <AuthSalesLedger />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "ledger-management/add-entries",
          element: isLoggedIn ? (
              <AuthAddJournalEntries />
          ) : (
            <Navigate to="/login" />
          ),
        },

        // WALLET
        {
          path: "wallet/payment-otp", element: isLoggedIn ? (
              <AuthWalletOTP />
          ) : (
            <Navigate to="/login" />
          )
        },
        {
          path: "wallet/onepoint-transfer", element: isLoggedIn ? (
              <AuthOnePointTrf />
          ) : (
            <Navigate to="/login" />
          )
        },
        {
          path: "wallet/create", element: isLoggedIn ? (
              <AuthCreateWallet />
          ) : (
            <Navigate to="/login" />
          )
        },
        {
          path: "wallet/withdraw", element: isLoggedIn ? (
              <AuthWithdrawFunds />
          ) : (
            <Navigate to="/login" />
          )
        },
        {
          path: "wallet/fund", element: isLoggedIn ? (
              <AuthFundWallet />
          ) : (
            <Navigate to="/login" />
          )
        },
        {
          path: "wallet/top-ups", element: isLoggedIn ? (
              <AuthWalletTopUps />
          ) : (
            <Navigate to="/login" />
          )
        },
        {
          path: "wallet/fees", element: isLoggedIn ? (
              <AuthWalletFeesPage />
          ) : (
            <Navigate to="/login" />
          )
        },
        {
          path: "wallet/convert-funds", element: isLoggedIn ? (
              <AuthConvertFunds />
          ) : (
            <Navigate to="/login" />
          )
        },
        {
          path: "wallet/transactions", element: isLoggedIn ? (
              <AuthMoreTransactions />
          ) : (
            <Navigate to="/login" />
          )
        },

        // TRANSFER
        // { path: 'transfer', element: <WalletTransfers /> },
        {
          path: "wallet",
          element: isLoggedIn ? (
              <AuthWalletPage />
          ) : (
            <Navigate to="/login" />
          ),
          children: [
              <WalletPage />
          ]
        },

        // SETTINGS
        {
          path: "user-settings",
          element: isLoggedIn ? (
              <AuthUserSettings />
          ) : (
            <Navigate to="/login" />
          ),
          children: [
              <UserSettings />
          ]
        },

        {
          path: "user-settings/custom-role", element: isLoggedIn ? (
              <AuthCustomRole />
          ) : (
            <Navigate to="/login" />
          )
        },
        {
          path: "user-settings/invite-team", element: isLoggedIn ? (
              <AuthInviteTeam />
          ) : (
            <Navigate to="/login" />
          )
        },
      ],
    },

    {
      path: "payment/invoice/preview/:id",
      element: <PreviewInvoice />
    },
    {
      path: "payment/invoice/checkout/:invoice_no",
      element: <InvoicePrevFromEmail />
    },
    {
      path: "payment-links/preview/:id",
      element: <PaymentLinkPreview />
    },
    {
      path: "landpage",
      element: <DashboardIndex />,
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "use-password",
      element: <UsePasswordLogin />,
    },
    {
      path: "payment-checkout/:trxRef",
      element: <PaymentCheckout />,
    },

    {
      path: "login-via-link",
      element: isLoggedIn ? (
          <AuthMagicLink />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "verify-auth",
      element: isLoggedIn ? (
          <AuthNew2FAVerification />
      ) : (
        <Navigate to="/login" />
      ),
    },

    {
      path: "complete-kyc",
      element: isLoggedIn ? (
          <AuthKYCSteps />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "customer/customer-statistics",
      element: isLoggedIn ? (
          <AuthCustomerGroupStat />
      ) : (
        <Navigate to="/login" />
      ),
    },

    {
      path: "signup",
      element: !isLoggedIn ? (
        <SignupPage />
      ) : (
        <Navigate to="/app/overview" />
      ),
    },
    {
      path: "forgot-password",
      element: !isLoggedIn ? (
        <ForgotPassword />
      ) : (
        <Navigate to="/app/overview" />
      ),
    },
    {
      path: "otp",
      element: !isLoggedIn ? (
        <OtpScreen />
      ) : (
        <Navigate to="/app/overview" />
      ),
    },
    {
      path: "verify-email",
      element: !isLoggedIn ? (
        <VerifyEmail />
      ) : (
        <Navigate to="/app/overview" />
      ),
    },
    {
      path: "verify-2fa",
      element: !isLoggedIn ? (
        <TWOFAAuth />
      ) : (
        <Navigate to="/app/overview" />
      ),
    },
    {
      path: "reset-password",
      element: !isLoggedIn ? (
        <ResetPassword />
      ) : (
        <Navigate to="/app/overview" />
      ),
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/landpage" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
