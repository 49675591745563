// src/App.js
import React, { useState, useEffect } from 'react';
import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';
import Preloader from './preloader';
import LazyLoad from 'react-lazyload';
import { Toaster } from 'react-hot-toast';
import { LanguageProvider } from './theme/languageContext'; // Import the LanguageProvider
import { I18nextProvider } from 'react-i18next';
import i18n from './theme/i18n'; // Import the i18n configuration

export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate the loading process
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  // const [showPreloader, setShowPreloader] = useState(true);

  // useEffect(() => {
  //   const handleLoad = () => {
  //     setLoading(false);
  //     setTimeout(() => setShowPreloader(false), 1000); // add an additional second for the fade-out effect
  //   };

  //   const minimumDisplayTime = setTimeout(() => {
  //     if (document.readyState === 'complete') {
  //       handleLoad();
  //     } else {
  //       window.addEventListener('load', handleLoad);
  //     }
  //   }, 1000); // minimum display time of 3 seconds

  //   return () => {
  //     clearTimeout(minimumDisplayTime);
  //     window.removeEventListener('load', handleLoad);
  //   };
  // }, []);

  // if (showPreloader) {
  //   return <Preloader />;
  // }

  return (
    <I18nextProvider i18n={i18n}>
    <LanguageProvider>
      <ThemeProvider>
        {isLoading ? (
          <Preloader />
        ) : (
          <>
          <Toaster position="top-center" />

            <ScrollToTop />
            <LazyLoad height={200} offset={100}>
              <Router />
            </LazyLoad>
          </>
        )}
      </ThemeProvider>
    </LanguageProvider>
    </I18nextProvider>
  );
}