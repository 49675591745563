import {
    Link,
} from "@mui/material";
import React
    //   { 
    //     useEffect, 
    //     useRef 
    //   } 
    from "react";
//   import { useInView } from "react-intersection-observer";
// import mystyle from "./scroll.module.css";
import styles from "./landpage.module.css";
import "../../Global.css";
import TopNavbar from "../../topnav";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";

// import FooterNavPage from "../../footernav";
//   import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

//   const AnimatedElement = ({ animationClass, children }) => {
//     const [ref, inView] = useInView({
//       triggerOnce: true, // Trigger animation only once
//     });

//     return (
//       <div
//         ref={ref}
//         className={inView ? `animate__animated ${animationClass}` : ""}
//         style={{ borderRadius: "12px" }}
//       >
//         {children}
//       </div>
//     );
//   };

export default function WebsiteLandPage() {

    const { data } = useAuth();
    let isLoggedIn = Object.keys(data).length;
    const navigate = useNavigate();

    const handleProceed = () => {
      navigate('/login');
    }

    const currentYear = new Date().getFullYear();

    // const navigate = useNavigate();

    // const handleClick = () => {
    //   navigate('/blog-details', { replace: true });
    // };

    // const anim01Ref = useRef(null);
    // const anim02Ref = useRef(null);
    // const anim03Ref = useRef(null);

    // useEffect(() => {
    //   const tabButtons = document.querySelectorAll(`.${styles.tab_buttons}`);
    //   const tabContents = document.querySelectorAll(".tab-pane");

    //   tabButtons.forEach((button, index) => {
    //     button.addEventListener("mouseover", () => {
    //       tabButtons.forEach((btn) => btn.classList.remove("active"));
    //       button.classList.add("active");

    //       tabContents.forEach((content) =>
    //         content.classList.remove("show", "active")
    //       );
    //       tabContents[index].classList.add("show", "active");
    //     });
    //   });
    // }, []);

    // useEffect(() => {
    //   const handleScroll = () => {
    //     var scrollVal = window.scrollY;
    //     var x1 = document.querySelector(".methods.mtd-easier").offsetTop + 900;
    //     var x2 = document.querySelector(".methods.mtd-faster").offsetTop + 900;
    //     var x3 = document.querySelector(".methods.mtd-cheaper").offsetTop + 900;
    //     //console.log(scrollVal, x1, x2, x3);
    //     if (scrollVal >= x1 && scrollVal < x2) {
    //       //Easier active
    //       const tabButtons = document.querySelectorAll("#transit h4");
    //       const h_easier = document.querySelector("#transit h4.h-easier");
    //       const tabContents = document.querySelectorAll(".methods");
    //       const mtd_easier = document.querySelector(".methods.mtd-easier");

    //       console.log('Tab Buttons : ', tabButtons);
    //       tabButtons.forEach((btn) => btn.classList.remove("active"));
    //       h_easier.classList.add("active");

    //       tabContents.forEach((content) =>
    //         content.classList.remove("active")
    //       );
    //       mtd_easier.classList.add("active");
    //       document.querySelector("#transit .counter").textContent = ("01");

    //     }
    //     if (scrollVal >= x2 && scrollVal < x3) {
    //       //Faster active
    //       const tabButtons = document.querySelectorAll("#transit h4");
    //       const h_easier = document.querySelector("#transit h4.h-faster");
    //       const tabContents = document.querySelectorAll(".methods");
    //       const mtd_easier = document.querySelector(".methods.mtd-faster");

    //       console.log('Tab Buttons : ', tabButtons);
    //       tabButtons.forEach((btn) => btn.classList.remove("active"));
    //       h_easier.classList.add("active");

    //       tabContents.forEach((content) =>
    //         content.classList.remove("active")
    //       );
    //       mtd_easier.classList.add("active");
    //       document.querySelector("#transit .counter").textContent = ("02");

    //     }
    //     if (scrollVal >= x3 + 500) {
    //       //Easier active
    //       const tabButtons = document.querySelectorAll("#transit h4");
    //       const h_easier = document.querySelector("#transit h4.h-cheaper");
    //       const tabContents = document.querySelectorAll(".methods");
    //       const mtd_easier = document.querySelector(".methods.mtd-cheaper");

    //       console.log('Tab Buttons : ', tabButtons);
    //       tabButtons.forEach((btn) => btn.classList.remove("active"));
    //       h_easier.classList.add("active");

    //       tabContents.forEach((content) =>
    //         content.classList.remove("active")
    //       );
    //       mtd_easier.classList.add("active");
    //       document.querySelector("#transit .counter").textContent = ("03");
    //     }
    //   };

    //   window.addEventListener('scroll', handleScroll);

    //   return () => {
    //     // Cleanup the event listener when the component unmounts
    //     window.removeEventListener('scroll', handleScroll);
    //   };
    // }, []);

    // const videoRef = useRef(null);

    // useEffect(() => {
    //   if (videoRef.current) {
    //     videoRef.current.muted=true
    //     // console.log(videoRef.current);
    //     const promise = videoRef.current.play(); 
    //     if(promise !== undefined){
    //       promise.then(() => {
    //           // Autoplay started
    //       }).catch(error => {
    //           // Autoplay was prevented.
    //           videoRef.current.muted = true;
    //           videoRef.current.autoplay=true;
    //       });
    //   }
    //   }
    // }, []);


    return (
        <>
            {/* <!-- For Nav Bar --> */}

            <TopNavbar />

            {/* <!-- Hero Section --> */}

            <div className="main-container" style={{ overflowX: "hidden" }}>
                <div className={`${styles.hero_home} hero_home_wrap`}>
                    {/* <img src="/assets/hero_img.jpeg" className="video-background" alt="" /> */}
                    <div className={`${styles.outer} container py-5 my-5`} style={{ position: 'relative' }}>
                        <div className={`${styles.inner} py-5 my-5 mjx-5`}>
                            <div
                                className="pb-2"
                            >
                                <span className={`${styles.slogan1} ${styles.top} font-weignt-bold`}>
                                    OnePoint Africa
                                </span>
                            </div>
                            <span className={`${styles.title}`}>
                                Pay Faster, <br />
                                Talk Better.
                            </span>
                            <br />
                            <div
                                className="pb-2"
                            >
                                <span className={`${styles.slogan1}`}>
                                    Discover secure and swift multi-currency payments, 
                                    payroll, OTP verifications, and personalized onboarding 
                                    via WhatsApp, SMS, and Email in Africa.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={` py-5 ${styles.second_segment}`} style={{ position: 'relative', background: '#F5F5F7' }}>
                    <div className="container py-5">
                        <div className={`${styles.second_segment_top} mb-5 pb-5`}>
                            <h1>
                                Explore our <br />
                                <div className="d-inline">Services</div>
                            </h1>
                            <p>Onepoint offers secure payment multi-currency support, payroll, invoicing, and seamless communication in Africa.</p>
                        </div>
                        <div className="row justify-content-end">
                            <div className=" col-md-6 col-lg-4 mb-4">
                                <div className={`px-4 pt-5 pb-4 card ${styles.second_segment_card}`}>
                                    <div className="d-flex flex-column justify-content-between align-items-start h-100" style={{ minHeight: '23em', position: 'relative' }}>
                                        <div>
                                            <h2>Payment <br /> <div className="d-inline">Solutions</div></h2>
                                            <p>With Onepoint’s dynamic solutions, you can perform secure and safe single and bulk payments in one go.</p>
                                        </div>
                                        <div className="ml-auto" style={{ width: 'fit-content' }}>
                                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="33" height="33" rx="16.5" fill="black" />
                                                <g clipPath="url(#clip0_575_2105)">
                                                    <path d="M22.4812 15.2708H17.7375V10.5187C17.7375 10.1905 17.6071 9.87578 17.375 9.64371C17.143 9.41163 16.8282 9.28125 16.5 9.28125C16.1718 9.28125 15.857 9.41163 15.625 9.64371C15.3929 9.87578 15.2625 10.1905 15.2625 10.5187V15.2625H10.5187C10.1905 15.2625 9.87578 15.3929 9.64371 15.625C9.41163 15.857 9.28125 16.1718 9.28125 16.5C9.28125 16.8282 9.41163 17.143 9.64371 17.375C9.87578 17.6071 10.1905 17.7375 10.5187 17.7375H15.2625V22.4812C15.2625 22.8095 15.3929 23.1242 15.625 23.3563C15.857 23.5884 16.1718 23.7188 16.5 23.7188C16.8282 23.7188 17.143 23.5884 17.375 23.3563C17.6071 23.1242 17.7375 22.8095 17.7375 22.4812V17.7375H22.4812C22.8095 17.7375 23.1242 17.6071 23.3563 17.375C23.5884 17.143 23.7188 16.8282 23.7188 16.5C23.7188 16.1718 23.5884 15.857 23.3563 15.625C23.1242 15.3929 22.8095 15.2625 22.4812 15.2625V15.2708Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_575_2105">
                                                        <rect width="16.5" height="16.5" fill="white" transform="translate(8.25 8.25)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-md-6 col-lg-4 mb-4">
                                <div className={`px-4 pt-5 pb-4 card ${styles.second_segment_card}`}>
                                    <div className="d-flex flex-column justify-content-between align-items-start h-100" style={{ minHeight: '23em', position: 'relative' }}>
                                        <div>
                                            <h2>Communication <br /> <div className="d-inline">Solutions</div></h2>
                                            <p>Secured OTP Disbursements, personalized message templates and single and bulk SMS distribution made easy for you.</p>
                                        </div>
                                        <div className="ml-auto" style={{ width: 'fit-content' }}>
                                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="33" height="33" rx="16.5" fill="black" />
                                                <g clipPath="url(#clip0_575_2105)">
                                                    <path d="M22.4812 15.2708H17.7375V10.5187C17.7375 10.1905 17.6071 9.87578 17.375 9.64371C17.143 9.41163 16.8282 9.28125 16.5 9.28125C16.1718 9.28125 15.857 9.41163 15.625 9.64371C15.3929 9.87578 15.2625 10.1905 15.2625 10.5187V15.2625H10.5187C10.1905 15.2625 9.87578 15.3929 9.64371 15.625C9.41163 15.857 9.28125 16.1718 9.28125 16.5C9.28125 16.8282 9.41163 17.143 9.64371 17.375C9.87578 17.6071 10.1905 17.7375 10.5187 17.7375H15.2625V22.4812C15.2625 22.8095 15.3929 23.1242 15.625 23.3563C15.857 23.5884 16.1718 23.7188 16.5 23.7188C16.8282 23.7188 17.143 23.5884 17.375 23.3563C17.6071 23.1242 17.7375 22.8095 17.7375 22.4812V17.7375H22.4812C22.8095 17.7375 23.1242 17.6071 23.3563 17.375C23.5884 17.143 23.7188 16.8282 23.7188 16.5C23.7188 16.1718 23.5884 15.857 23.3563 15.625C23.1242 15.3929 22.8095 15.2625 22.4812 15.2625V15.2708Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_575_2105">
                                                        <rect width="16.5" height="16.5" fill="white" transform="translate(8.25 8.25)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`${styles.second_segment_next} mt-md-5 mb-3 py-5`}>
                            <div className="mt-md-5">
                                <h1>
                                    Unlimited payment solutions for your <br />
                                    <div className="d-inline">business.</div>
                                </h1>
                            </div>
                            {/* <p>Onepoint offers secure payment multi-currency support, payroll, invoicing, and seamless communication in Africa.</p> */}
                        </div>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 mb-4">
                                <div className={`${styles.transfers_text_wrap}`}>
                                    <h3>Transfers (Payout)</h3>
                                    <p>Easily process single and bulk payment to anyone, anywhere, anytime and in any currency using our secured payment channel.</p>
                                </div>
                            </div>
                            <div className="col-md-6 mb-md-4">
                            </div>
                            <div className="col-md-6 mb-4 px-lg-0">
                                <img src="/assets/card_image.png" alt="" className="img-fluid img-responsive" />
                            </div>
                            <div className="col-md-5 mb-md-4 px-lg-0">
                                <div className={`${styles.transfers_text_wrap} pt-4 pt-md-0 mb-5 pb-md-5`}>
                                    <h3>Airtime/Data</h3>
                                    <p>Effortlessly send single or bulk airtime payments and allocate data at your convenience.</p>
                                </div>
                                <div className={`${styles.transfers_text_wrap} mb-5 pb-md-5`}>
                                    <h3>Payroll</h3>
                                    <p>Ensure your team receives accurate and timely payments, as we manage your employee's compensation seamlessly.</p>
                                </div>
                                <div className={`${styles.transfers_text_wrap} mb-5 mb-md-0 pb-md-5`}>
                                    <h3>Invoice</h3>
                                    <p>Create and document a list of products or services provided and their costs. Also, detail payments received, balances owed, and installment plans.</p>
                                </div>
                            </div>
                            <div className="col-md-6 mb-md-4">
                                <div className={`${styles.transfers_text_wrap}`}>
                                    <div className="" style={{ width: '18em', maxWidth: '100%' }}>
                                        <h3>Ledger Management</h3>
                                    </div>
                                    <p>Organize all your day-to-day transactions for accurate records, transparency and effective financial management</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={` ${styles.image_segment}`}></div>
                <div className={` ${styles.image_segment_img}`}>
                    <img src="/assets/intersect_img.jpeg" alt="" className="img-fluid img-responsive" />
                </div>


                <div className={` py-5 ${styles.third_segment}`} style={{ position: 'relative', background: '#FFFFFF' }}>
                    <div className="container py-3 py-md-5">
                        <div className={`${styles.third_segment_top} mb-md-5 pb-5`}>
                            <h1 className="text-center">
                                Limitless Ways to communicate for your <div className="d-inline">Business</div>
                            </h1>
                            {/* <p>Onepoint offers secure payment multi-currency support, payroll, invoicing, and seamless communication in Africa.</p> */}
                        </div>
                        <div className="row justify-content-center">
                            <div className=" col-md-6 col-lg-4 mb-4">
                                <div className={`px-4 pt-5 pb-4 card ${styles.second_segment_card} ${styles.below}`} style={{ background: '#F5F5F7' }}>
                                    <div className="d-flex flex-column justify-content-between align-items-start h-100" style={{ minHeight: '23em', position: 'relative' }}>

                                        <h2 className="mb-0 pb-0">SMS</h2>
                                        <p className="" style={{ fontSize: '19px', color: '#6B7280' }}>Efficiently Interact with and onboard your new customers using our unique and tailored to taste SMS templates.</p>
                                        <div className="ml-auto" style={{ width: 'fit-content' }}>
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M27.4703 48.8064C26.9768 48.0105 26.5548 47.0164 26.2051 45.8231L22.4109 33.2214C22.1876 32.4259 22.1081 31.7858 22.1721 31.3003C22.2353 30.8151 22.4975 30.3256 22.9592 29.8325L47.3271 3.55622C47.4701 3.41278 47.5416 3.25344 47.5416 3.07861C47.5416 2.90338 47.4781 2.76034 47.3505 2.64869C47.2234 2.53783 47.0764 2.47783 46.9091 2.46988C46.7422 2.46193 46.5868 2.52988 46.4438 2.67332L20.2625 27.1357C19.7376 27.6133 19.2282 27.8799 18.7355 27.9356C18.2416 27.9912 17.6134 27.8994 16.8497 27.661L3.9619 23.7464C2.81636 23.3967 1.8695 22.9791 1.1217 22.4936C0.373899 22.0084 0 21.2169 0 20.119C0 19.26 0.342509 18.5201 1.02634 17.8995C1.71056 17.2792 2.55412 16.7782 3.55622 16.3963L44.6061 0.667933C45.1628 0.461314 45.6841 0.298404 46.1696 0.179202C46.6544 0.059602 47.0962 0 47.494 0C48.2731 0 48.8858 0.222512 49.3317 0.667933C49.7771 1.11375 50 1.72606 50 2.50564C50 2.91967 49.9404 3.36509 49.8208 3.8423C49.7016 4.31991 49.5383 4.83685 49.3317 5.39393L33.6994 46.2288C33.2536 47.3744 32.7208 48.289 32.1001 48.9733C31.4799 49.6575 30.74 49.9992 29.8806 49.9992C28.7668 49.9992 27.963 49.6019 27.4703 48.8064Z" fill="#111827" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-md-6 col-lg-4 mb-4">
                                <div className={`px-4 pt-5 pb-4 card ${styles.second_segment_card} ${styles.below}`} style={{ background: '#F5F5F7' }}>
                                    <div className="d-flex flex-column justify-content-between align-items-start h-100" style={{ minHeight: '23em', position: 'relative' }}>

                                        <h2 className="mb-0 pb-0">OTP</h2>
                                        <p className="" style={{ fontSize: '19px', color: '#6B7280' }}>Quickly confirm and authenticate your customer identity using SMS, email and Whatsapp.</p>
                                        <div className="ml-auto" style={{ width: 'fit-content' }}>
                                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M41.715 9.27014L29.34 4.63514C28.0575 4.16264 25.965 4.16264 24.6825 4.63514L12.3075 9.27014C9.92249 10.1701 7.98749 12.9601 7.98749 15.5026V33.7276C7.98749 35.5501 9.17999 37.9576 10.6425 39.0376L23.0175 48.2851C25.2 49.9276 28.7775 49.9276 30.96 48.2851L43.335 39.0376C44.7975 37.9351 45.99 35.5501 45.99 33.7276V15.5026C46.0125 12.9601 44.0775 10.1701 41.715 9.27014ZM34.83 21.8701L25.155 31.5451C24.8175 31.8826 24.39 32.0401 23.9625 32.0401C23.535 32.0401 23.1075 31.8826 22.77 31.5451L19.17 27.9001C18.5175 27.2476 18.5175 26.1676 19.17 25.5151C19.8225 24.8626 20.9025 24.8626 21.555 25.5151L23.985 27.9451L32.4675 19.4626C33.12 18.8101 34.2 18.8101 34.8525 19.4626C35.505 20.1151 35.505 21.2176 34.83 21.8701Z" fill="#111827" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={` pt-5 pb-3 ${styles.last_segment}`} style={{ position: 'relative', background: '#F5F5F7' }}>
                    <div className="container d-md-flex justify-content-between align-items-center py-5">
                        <div className={`${styles.last_segment_top} `}>
                            <h1>
                                Let the <br />
                                <div className="d-inline">countdown</div> begin
                            </h1>
                            <p>Get on our waitlist now and be one of the first to know when we launch!</p>
                        </div>
                        <div className={` py-5 ${styles.last_segment_btn} d-flex justify-content-center align-items-center `}>
                            <div className={`${styles.hero_btn}`}>
                            {isLoggedIn ? (
                                <Link to="/app/overview" component={RouterLink}>Join the waitlist</Link>
                            ) : (
                                <Link to="/login" component={RouterLink}>Join the waitlist</Link>
                            )}
                            </div>
                        </div>

                    </div>
                    <div className="text-center mt-5 pt-5">
                    <p className="" style={{ color: '#4B5563' }}>© {currentYear} Eaven, Inc. All Rights Reserved.</p>
                    </div>
                </div>

                {/* <!-- /* For Footer intersect_img  --> */}

                {/* <FooterNavPage /> */}
            </div>
        </>
    );
}
