import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import axiosInstance from "src/axiosInstance"
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@mui/material/TextField";
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import TransactionPINDialog from 'src/Modals/TransactionPINDialog';
import * as dateFns from "date-fns"
import Papa from 'papaparse';

import {
  Link,
  Checkbox,
  Stack,
  Drawer,
  Typography,
  IconButton,
  Divider
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GlobalModal from "../../../Modals/GlobalModal";
import Scrollbar from '../../../components/scrollbar';
import styles from "../Wallet.module.css";
import Iconify from '../../../components/iconify';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import { filter } from "lodash";

// ----------------------------------------------------------------------
const formatDateWithSuffix = (date) => {
  const day = format(date, 'do'); // 'do' gives the day of the month with ordinal suffix (e.g., 1st, 2nd, 3rd)
  const month = format(date, 'MMMM');
  const year = format(date, 'yyyy');
  return `${day} ${month} ${year}`;
};
const SCHEDULE_TRANSFER_FREQUENCY = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  CUSTOM: 'CUSTOM',
}
const SCHEDULE_TRANSFER_INTERVAL = {
  EVERY_5_DAYS: 'EVERY_5_DAYS',
  EVERY_2_WEEKS: 'EVERY_2_WEEKS',
  EVERY_2_MONTHS: 'EVERY_2_MONTHS',
  BI_ANNUALLY: 'BI_ANNUALLY',
  QUARTERLY: 'QUARTERLY',
}
export default function TransferToBankBulk() {
  const [isPINDialogOpen, setIsPINDialogOpen] = useState(false);
  const [pinError, setPinError] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_NAME;
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [trsnPinDialog, setTrsnPinDialog] = useState(false);
  const [paymentSuccessfulDialog, setPaymentSuccessfulDialog] = useState(false);
  const [save, setSave] = useState(false);
  const [schedulePayment, setSchedulePayment] = useState(false);
  const [scheduledOption, setScheduledOption] = useState('DAILY');
  const defaultTime = '00:00'; // Default time in 24-hour format
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [paymentTime, setPaymentTime] = useState(defaultTime);
  const [previewPaymentTime, setPreviewPaymentTime] = useState(null);
  const [interval, setInterval] = useState('EVERY_5_DAYS');
  const [noOfTimes, setNoOfTimes] = useState(1);
  const [walletData, setWalletData] = useState([])
  const [exchangeRate, setExchangeRate] = useState('')
  const [rateLoading, setRateLoading] = useState(false)
  const [rateError, setRateError] = useState('')
  const [isCreating, setIsCreating] = useState(false);
  const [isLoadingTransfer, setIsLoadingTransfer] = useState(false);
  const [transferDetails, setTransferDetails] = useState({
    wallet_ref: '',
    pin: '',
    description: '',
    schedule: {
      time: '',
      interval: '',
      frequency: '',
      number_of_occurance: '',
    },
  });

  useEffect(() => {
    if(paymentTime && scheduledOption) {
      getNextPaymentDate(paymentTime, scheduledOption, noOfTimes, interval)
    }
  },[paymentTime, scheduledOption,interval])

  const getNextPaymentDate =(time, frequency, number_of_occurance = noOfTimes, interval) => {
    const [hours, minutes] = time.split(':').map(Number);
    let nextDate = new Date();

    // Set the time
    nextDate.setHours(hours, minutes, 0, 0);

    switch (frequency) {
        case SCHEDULE_TRANSFER_FREQUENCY.DAILY:
            nextDate = dateFns.addDays(nextDate, 1);
            break;
        case SCHEDULE_TRANSFER_FREQUENCY.WEEKLY:
            nextDate = dateFns.addWeeks(nextDate, 1);
            break;
        case SCHEDULE_TRANSFER_FREQUENCY.MONTHLY:
            nextDate = dateFns.addMonths(nextDate, 1);
            break;
        case SCHEDULE_TRANSFER_FREQUENCY.QUARTERLY:
            nextDate = dateFns.addQuarters(nextDate, 1);
            break;
        case SCHEDULE_TRANSFER_FREQUENCY.CUSTOM:
            if (number_of_occurance > 0 && interval) {
                switch (interval) {
                    case SCHEDULE_TRANSFER_INTERVAL.EVERY_5_DAYS:
                      // console.log(number_of_occurance);
                        nextDate = dateFns.addDays(nextDate, 5 );
                        break;
                    case SCHEDULE_TRANSFER_INTERVAL.EVERY_2_WEEKS:
                        nextDate = dateFns.addWeeks(nextDate, 2 );
                        break;
                    case SCHEDULE_TRANSFER_INTERVAL.EVERY_2_MONTHS:
                        nextDate = dateFns.addMonths(nextDate, 2 );
                        break;
                    case SCHEDULE_TRANSFER_INTERVAL.BI_ANNUALLY:
                        nextDate = dateFns.addMonths(nextDate, 6 );
                        break;
                    case SCHEDULE_TRANSFER_INTERVAL.QUARTERLY:
                        nextDate = dateFns.addQuarters(nextDate, 1);
                        break;
                    default:
                        throw new Error('Invalid custom interval');
                }
            }
            break;
        default:
            // throw new Error('Invalid frequency');
    }
    setPaymentDate(nextDate)
    return nextDate;
  }

  const openPINDialog = () => setIsPINDialogOpen(true);
  const closePINDialog = () => setIsPINDialogOpen(false);


  const handleNoOfTimesChange = (event) => {
    setNoOfTimes(event.target.value); // Update the state with the user's typed value
  };

  const handleInterval = (event) => {
    setInterval(event.target.value); // Update the state with the user's typed value
  };

  const handleTimeChange = (event) => {
    const time = event.target.value;
    setPaymentTime(time);
    // console.log('event value:', time);

    // Format the time to display as 12:00 AM and 12:00 PM, not 14:00
    const formattedTime = formatTimeTo12Hour(time);
    setPreviewPaymentTime(formattedTime);
  };

  const formatTimeTo12Hour = (time) => {
    const [hours, minutes] = time.split(':');
    let hour = parseInt(hours, 10);
    const isPM = hour >= 12;
    hour = hour % 12 || 12; // Convert 24-hour to 12-hour format, handling midnight as 12
    const formattedHour = hour.toString().padStart(2, '0');
    const period = isPM ? 'PM' : 'AM';
    return `${formattedHour}:${minutes} ${period}`;
  };


  const handleClosePinDialog = () => {
    localStorage.setItem("show_ledger_banner", JSON.stringify(true));
    navigate('/app/payment/transfers')
  };

  const handleChangeSave = () => {
    setSchedulePayment(true);
    // setSave(!save);
    setSave(false);
  }

  const verifyScheduleTrue = () => {
    setSchedulePayment(false);
    setSave(true);
  }

  const handleScheduleChange = (event) => {
    setScheduledOption(event.target.value);
    if (event.target.value !== 'CUSTOM') {
      setInterval('')
      setNoOfTimes(null)
    }
  };


  const handleCloseSchedulePayment = () => {
    setSchedulePayment(false);
  };

  const handleClosePaymentSuccessfulDialog = () => {
    setPaymentSuccessfulDialog(false)

  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      setSelectedFile(file);
      setErrorMessage("");
      readFileContent(file);
    } else {
      setSelectedFile(null);
      setFileContent("");
      setErrorMessage("Please select a CSV file only.");
    }
  };


  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      setSelectedFile(file);
      setErrorMessage("");
      readFileContent(file);
    } else {
      setSelectedFile(null);
      setFileContent("");
      setErrorMessage("Please select a CSV file only.");
    }
  };

  const readFileContent = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setFileContent(reader.result);
    };
    reader.readAsText(file);
  };

  const handleReset = () => {
    setSelectedFile(null);
    setFileContent("");
    setErrorMessage("");
  };

  const handleRoute = () => {
    setPaymentSuccessfulDialog(false);
    localStorage.setItem('show_ledger_banner', JSON.stringify(true));
    navigate('/app/payment/transfers');
  }

  const handleTransferDetails = (key, value) => {
    setTransferDetails((prev) => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const readFileAsText = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  // Helper function to convert file to base64
const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

  const handleSendTransfer = async (pin) => {
    if(pin === "" || pin.length < 4) {
      return notifyError('Input transaction pin to proceed')
    }
    transferDetails.pin = pin
    setIsLoadingTransfer(true)
    let filter_payload = {}
    if (save) {
      filter_payload.schedule = {}

      if (paymentTime !== null) {
        // Remove "AM" or "PM" from previewPaymentTime
        const cleanedTime = paymentTime.replace(/AM|PM/, '').trim();
        filter_payload.schedule.time = cleanedTime;
      }
      if (scheduledOption !== '') {
        filter_payload.schedule.frequency = scheduledOption.toLocaleUpperCase()
      }
      if (scheduledOption === 'CUSTOM' && noOfTimes !== null) {
        filter_payload.schedule.number_of_occurance = noOfTimes
      }
      if (scheduledOption === 'CUSTOM' && interval !== '') {
        filter_payload.schedule.interval = interval
      }
    }
    if (transferDetails.wallet_ref !== '') {
      filter_payload.wallet_ref = transferDetails.wallet_ref
    }
    if (transferDetails.pin !== '') {
      filter_payload.pin = transferDetails.pin
    }
    if (transferDetails.description !== '') {
      filter_payload.reason = transferDetails.description
    }

    // if (selectedFile) {
    //   const base64File = await toBase64(selectedFile);
    //   filter_payload.file = base64File;
    // }

    if (selectedFile) {
      try {
        // Read the file as text
        const fileContent = await readFileAsText(selectedFile);
  
        // Parse the CSV content
        const parsed = Papa.parse(fileContent, {
          header: true,
          skipEmptyLines: true
        });
  
        const userHeaderMapping = {
          "Bank code": "bankCode",
          "Account number": "accountNumber",
          "Amount": "amount"
        };
        
        // Function to dynamically map headers based on user input
        const mapHeaders = (data, headerMapping) => {
          return data.map(row => {
            const updatedRow = {};
            Object.keys(headerMapping).forEach(userKey => {
              const desiredKey = headerMapping[userKey];
              if (row[userKey] !== undefined) {
                updatedRow[desiredKey] = row[userKey];
                // console.log(desiredKey);
              }
            });
            return updatedRow;
          });
        };
        
        // Assume `parsed.data` contains the parsed CSV data as an array of objects
        const updatedData = mapHeaders(parsed.data, userHeaderMapping);
  
        // Convert updated data back to CSV
        const updatedCSV = Papa.unparse(updatedData);
  
        // Convert updated CSV to a file
        const updatedFile = new File([updatedCSV], selectedFile.name, { type: selectedFile.type });
  
        // Convert file to base64
        const base64File = await toBase64(updatedFile);
        filter_payload.file = base64File;
      } catch (error) {
        notifyError('Error processing the file');
        setIsLoadingTransfer(false);
        return;
      }
    }
  

    // const formData = new FormData();
    // Object.keys(filter_payload).forEach(key => formData.append(key, filter_payload[key]));

    try {
      const response = await axiosInstance.post(`/payments/transfers/bulk?productName=${apiUrl}`, filter_payload)
      notifySuccess(response.data.message)
      handleClosePinDialog()
      closePINDialog();
      setIsLoadingTransfer(false)
    } catch (err) {
      notifyError(err.response.data.data.errorMessage)
      setIsLoadingTransfer(false)
    } finally {
      setIsLoadingTransfer(false)
    } 
  }

  const fetchWalletData = async () => {
    setIsCreating(true);

    try {
      const response = await axiosInstance.get(`/wallet/my-wallets?productName=${apiUrl}`);

      setWalletData(response.data.data.data);
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }

    } finally {

      setIsCreating(false);
    }
  };

  const fetchExchangeRate = async () => {
    const apiKey = '2a80c724e7f96161953be8ae'; // Replace with your actual API key
    const endpoint = `https://v6.exchangerate-api.com/v6/${apiKey}/pair/USD/NGN`;

    try {
      const response = await axios.get(endpoint);
      const rate = response.data.conversion_rate;
      setExchangeRate(rate);
      setRateLoading(false);
    } catch (err) {
      setRateError('Failed to fetch exchange rate');
      setRateLoading(false);
    }
  };

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  useEffect(() => {
    fetchWalletData();
  }, [])

  const fetchBankDataAndDownload = async () => {
    const payload = {
      name: 'Monnify'
    }
    try {
      // Fetch the bank data from the endpoint
      const response = await axiosInstance.post(`/providers/banks?productName=${apiUrl}`, {
        name: 'Monnify'
      });
      const bankData = response.data.data.data;

      // Create an array of arrays to represent the rows and columns of the Excel sheet
      const excelData = [
        ['Bank Name', 'Bank Code'], // Headers
        ...bankData.map(bank => [bank.bank_name, bank.bank_code]), // Data rows
      ];

      // Create a new workbook and add a worksheet with the data
      const worksheet = XLSX.utils.aoa_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Banks');

      // Convert the workbook to a binary string
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

      // Create a Blob from the binary string
      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

      // Trigger the file download
      saveAs(blob, 'bank_codes.xlsx');
    } catch (error) {
      toast.error('Error fetching bank data or generating Excel file:', error);
    }
  };

  const generateCSV = () => {
    const headers = ['Account number', 'Bank code', 'Amount'];
    const rows = [
      ['4936678013', '214', '20'],
      ['0036262435', '221', '20'],
      ['8121253150', '100004', '20'],
    ];
  
    let csvContent = "data:text/csv;charset=utf-8,";
  
    // Add headers
    csvContent += headers.join(",") + "\r\n";
  
    // Add rows
    rows.forEach(row => {
      csvContent += row.join(",") + "\r\n";
    });
  
    return encodeURI(csvContent);
  };
  
  const handleDownload = () => {
    const csvContent = generateCSV();
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', 'BulkTransfer-Sample.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  };

  // Helper function to convert a string to an ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file && file.type === "text/csv") {
  //     setSelectedImage(URL.createObjectURL(file));
  //     setErrorMessage("");
  //   } else {
  //     setSelectedImage(null);
  //     setErrorMessage("Please select a CSV file only.");
  //   }
  // };

  // const handleDragOver = (event) => {
  //   event.preventDefault();
  // };

  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   const file = event.dataTransfer.files[0];
  //   if (file && file.type === "text/csv") {
  //     setSelectedImage(URL.createObjectURL(file));
  //     setErrorMessage("");
  //   } else {
  //     setSelectedImage(null);
  //     setErrorMessage("Please select a CSV file only.");
  //   }
  // };

  return (
    <>
      <Helmet>
        <title> Bank Transfer - Bulk Payment </title>
      </Helmet>
      <div className="wrapper">
        <div className={`${styles.header_wrap} d-sm-flex justify-content-between align-items-center`}>
          <div className="d-flex">
            <Link
              className="d-block text-secondary"
              to="/app/payment/transfers"
              component={RouterLink}
            >
              <i
                className="bi bi-x"
                style={{ fontSize: "1.5em", lineHeight: "1" }}
              ></i>
            </Link>
            <h6 className="pl-2 pl-sm-3 mb-0 fw-700 pb-0" style={{ lineHeight: '1.5' }} >Bulk Transfer</h6>
          </div>
          <div className="header_btn_group d-none d-sm-block mt-2 mt-sm-0">
            <div className="btn-group">
              <button
                className="btn border btn-primary"
                onClick={openPINDialog}
              >
                <span className="button_text text-white">Send Transfer</span>
              </button>
            </div>
          </div>
        </div>

        <div className="header_btn_group d-block d-sm-none mt-3 mt-sm-0">
          <div className="btn-group">
            <button
              className="btn border btn-primary"
              // onClick={() => {
              //   setTrsnPinDialog(true);
              // }}
              disabled={selectedFile === null || transferDetails.wallet_ref === ''}
              onClick={openPINDialog}
            >
              <span className="button_text text-white">Send Transfer</span>
            </button>
          </div>
        </div>

        <div className="row justify-content-center mt-4">
          <div className="col-md-10 pb-5">
            <div className={`${styles.trftobank_side_wrap} mx-auto px-0 px-sm-2 py-sm-4`} style={{ width: '29em' }}>
              <div>
                <div className={`${styles.requirement_wrap} pb-2`}>
                  <h5 className="font-weight-normal">Requirements</h5>
                  <ul className={`${styles.requirement_ul}`}>
                    <li>The file must be CSV</li>
                    <li>
                      CSV file should contain the following columns - Account
                      number, Bank code, and Amount.
                    </li>
                    <li>
                      View{" "}
                      <span className="text-primary text-underline fw-500" onClick={fetchBankDataAndDownload}>
                        list of bank codes
                      </span>
                    </li>
                    <li>
                      The order of the columns should be same as the order in which
                      they are listed above with the first row as headers.
                    </li>
                  </ul>
                  <span className="d-block pl-3">
                    Get a sample{" "}
                    <span className="text-primary fw-500">
                      <a onClick={handleDownload} className="text-primary text-underline">
                        bulk payment CSV file
                      </a>
                    </span>
                  </span>
                </div>

                <div
                  className="drop_zone my-4"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  {selectedFile &&
                    <div className="change_csv_container d-flex ">
                      <div className="change_csv_wrap btn border p-0">
                        <span className="position-absolute">Change</span>
                        <input
                          type="file"
                          onChange={handleFileChange}
                          onDrop={handleDrop}
                          accept=".csv"
                          className="change_csv"
                        />
                      </div>

                      <button className="btn d-block border m-0 text-danger" onClick={handleReset}><i className="bi bi-arrow-repeats"></i>Delete</button>
                    </div>
                  }
                  {!selectedFile &&
                    <input
                      type="file"
                      onChange={handleFileChange}
                      onDrop={handleDrop}
                      accept=".csv"
                    />
                  }

                  {!selectedFile && (
                    <div>
                      <div className="mx-auto" style={{ width: "fit-content" }}>
                        <svg
                          width="46"
                          height="46"
                          viewBox="0 0 46 46"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="3"
                            y="3"
                            width="40"
                            height="40"
                            rx="20"
                            fill="#F2F4F7"
                          />
                          <rect
                            x="3"
                            y="3"
                            width="40"
                            height="40"
                            rx="20"
                            stroke="#F9FAFB"
                            strokeWidth="6"
                          />
                          <g clipPath="url(#clip0_1729_11206)">
                            <path
                              d="M26.3333 26.3332L23 22.9999M23 22.9999L19.6666 26.3332M23 22.9999V30.4999M29.9916 28.3249C30.8044 27.8818 31.4465 27.1806 31.8165 26.3321C32.1866 25.4835 32.2635 24.5359 32.0351 23.6388C31.8068 22.7417 31.2862 21.9462 30.5555 21.3778C29.8248 20.8094 28.9257 20.5005 28 20.4999H26.95C26.6977 19.5243 26.2276 18.6185 25.5749 17.8507C24.9222 17.0829 24.104 16.4731 23.1817 16.0671C22.2594 15.661 21.2571 15.4694 20.2501 15.5065C19.243 15.5436 18.2575 15.8085 17.3676 16.2813C16.4777 16.7541 15.7066 17.4225 15.1122 18.2362C14.5177 19.05 14.1155 19.9879 13.9358 20.9794C13.756 21.9709 13.8034 22.9903 14.0743 23.961C14.3452 24.9316 14.8327 25.8281 15.5 26.5832"
                              stroke="#475467"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1729_11206">
                              <rect
                                width="20"
                                height="20"
                                fill="white"
                                transform="translate(13 13)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <p className="mb-1">
                        <span className="text-primary">Click to upload</span> or
                        drag and drop
                      </p>
                      <p className="mb-1">CSV file only</p>
                      {errorMessage && <p className="text-danger fs-12">{errorMessage}</p>}
                    </div>
                  )}
                  {selectedFile &&
                    <>
                      {/* <h3 className="text-center">File Content:</h3> */}
                      <div className="file_preview"
                        onChange={handleFileChange}
                      >
                        {/* {fileContent} */}
                        <div className=" w-100">
                          <table className="table" style={{ minWidth: '40em' }}>
                            <thead>
                              <tr>
                                {fileContent &&
                                  fileContent.split('\n')[0].split(',').map((header, index) => (
                                    <th key={index}>{header.trim().replace(/"/g, '')}</th>
                                  ))
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {fileContent &&
                                fileContent.split('\n').slice(1).map((row, index) => (
                                  <tr key={index}>
                                    {row.split(',').map((cell, index) => (
                                      <td key={index}>{cell.trim().replace(/"/g, '')}</td>
                                    ))}
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <img src={selectedFile} alt="Selected" /> */}
                    </>
                  }
                  {/* <p className="mb-0">{selectedImage ? "1 file selected" : "No file selected"}</p> */}
                </div>

                <div className="mb-1">
                  <label htmlFor="name">
                    Select Wallet
                    {isCreating && (
                      <CircularProgress
                        style={{ color: "#222222" }}
                        className="text-white ml-1"
                        size="0.8rem"
                      />
                    )}
                  </label>
                  <div className="position-relative">
                    <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z" fill="#6B7280" />
                      </svg>
                    </span>
                    <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      required
                      name="wallet_ref"
                      defaultValue="NGN"
                      value={transferDetails.wallet_ref}
                      onChange={(e) => handleTransferDetails('wallet_ref', e.target.value)}
                    >
                      {
                        walletData && walletData.map((el, i) => {
                          return (

                            <MenuItem value={el?.wallet_ref}>{el?.name} - ₦{el?.balance}</MenuItem>
                          )
                        })
                      }
                      {
                        !walletData.length && (
                          <MenuItem value="">No Wallet Found</MenuItem>
                        )
                      }
                    </Select>
                  </div>
                  {/* <small className="text-secondary font-weight-normal" style={{ fontSize: '12px' }}>
                    Exchange rate $1 = N{Number(exchangeRate).toLocaleString() || 0}
                  </small> */}
                  {/* <small className="text-danger d-block" style={{ fontSize: '12px' }}>{rateError}</small> */}
                </div>

                <div className="">
                  <label htmlFor="description">Description</label>
                  <div className="" style={{ width: "100%" }}>
                    <div className="">
                      <TextField
                        name="description"
                        required
                        type="text"
                        id="description"
                        size="small"
                        placeholder=""
                        fullWidth
                        value={transferDetails.description}
                        onChange={(e) => handleTransferDetails('description', e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="checkbox_div pt-2 mb-3">
                  <Checkbox
                    id="schedule"
                    checked={save}
                    onClick={handleChangeSave}
                  // disabled={save} 
                  />
                  <label htmlFor="schedule" className="ml-1 mb-0" style={{ fontSize: '12px', cursor: 'pointer' }}>Schedule payment <span className="ml-1"><svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5.5" cy="5.5" r="5.5" fill="#6B7280" />
                    <path d="M5.3125 7.03587V6.98864C5.31581 6.6804 5.34647 6.43513 5.40447 6.25284C5.46413 6.07055 5.54865 5.92306 5.65803 5.81037C5.7674 5.69768 5.89915 5.59493 6.05327 5.50213C6.1527 5.43916 6.24219 5.36873 6.32173 5.29084C6.40128 5.21295 6.46425 5.12346 6.51065 5.02237C6.55706 4.92128 6.58026 4.80942 6.58026 4.68679C6.58026 4.5393 6.54545 4.4117 6.47585 4.30398C6.40625 4.19626 6.31345 4.1134 6.19744 4.0554C6.0831 3.99574 5.95549 3.96591 5.81463 3.96591C5.68703 3.96591 5.56522 3.99242 5.44922 4.04545C5.33321 4.09848 5.2371 4.18134 5.16087 4.29403C5.08464 4.40507 5.04072 4.54841 5.02912 4.72408H4.27344C4.28504 4.42578 4.36044 4.17389 4.49964 3.96839C4.63885 3.76125 4.8228 3.60464 5.05149 3.49858C5.28184 3.39252 5.53622 3.33949 5.81463 3.33949C6.11956 3.33949 6.38636 3.39666 6.61506 3.51101C6.84375 3.6237 7.02107 3.78196 7.14702 3.9858C7.27462 4.18797 7.33842 4.42412 7.33842 4.69425C7.33842 4.87985 7.30942 5.04723 7.25142 5.19638C7.19342 5.34387 7.11056 5.47562 7.00284 5.59162C6.89678 5.70762 6.76918 5.81037 6.62003 5.89986C6.47917 5.98769 6.36482 6.07884 6.27699 6.1733C6.19081 6.26776 6.12784 6.37962 6.08807 6.50888C6.0483 6.63814 6.02675 6.79806 6.02344 6.98864V7.03587H5.3125ZM5.68786 8.54723C5.55197 8.54723 5.43513 8.49917 5.33736 8.40305C5.23958 8.30528 5.1907 8.18762 5.1907 8.05007C5.1907 7.91418 5.23958 7.79818 5.33736 7.70206C5.43513 7.60428 5.55197 7.5554 5.68786 7.5554C5.82209 7.5554 5.93809 7.60428 6.03587 7.70206C6.1353 7.79818 6.18501 7.91418 6.18501 8.05007C6.18501 8.14122 6.16181 8.22491 6.11541 8.30114C6.07067 8.37571 6.01101 8.43537 5.93643 8.48011C5.86186 8.52486 5.779 8.54723 5.68786 8.54723Z" fill="white" />
                  </svg>
                  </span></label>
                </div>

                {save && (
                  <div className="">
                    <label htmlFor="ounit">Your Next payment is on</label>
                    <div className="cursor-pointer" style={{ width: "100%" }} onClick={handleChangeSave}>
                      <div
                        className="mb-3 w-100 custom_short_datepkr bg-light"
                        style={{ position: "relative" }}
                      >

                        <div className="p-2 border rounded-0 ">
                          <p className="mb-0">
                            {format(paymentDate, "do MMMM yyyy - h:mmaaa")}
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

      </div>



      <TransactionPINDialog
        open={isPINDialogOpen}
        onClose={closePINDialog}
        onSubmit={handleSendTransfer}
        isLoading={isLoadingTransfer}
        pinError={pinError}

      />


      <GlobalModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={paymentSuccessfulDialog}
        onClose={handleClosePaymentSuccessfulDialog}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="500px !important"
        maxWidth="100%"
        overflowY="scroll"
        bgcolor="#FFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div
          className={`modal_content`}
          style={{ overflowY: 'scroll', height: 'auto', }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="48" height="48" rx="24" fill="#D1FAE5" />
              <path d="M17 25L21 29L31 19" stroke="#059669" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

            <div className="text-center pt-4">
              <h6>
                <span className="text-dark modals_head_title" style={{ fontWeight: '500' }}>Payment Successful</span>
              </h6>
              <p style={{ fontWeight: '300', color: 'rgba(75, 85, 99, 1)' }}>
                <small>Your transfer is confirmed and on its way</small>
              </p>
            </div>

            <div
              className="mt-2 modal_content w-100"
              style={{ overflowY: "scroll" }}
            >
              <div className="d-flex" style={{ gap: '10px' }}>
                <button type="button" onClick={handleRoute} className="d-block w-100 btn py-2 border"><span>Cancel</span></button>
                <button onClick={handleRoute} className="d-block w-100 btn py-2 btn-primary"><span className="text-white font-weight-normal">Save Beneficiary</span></button>
              </div>
            </div>
          </div>

        </div>

      </GlobalModal>




      <Drawer
        anchor="right"
        open={schedulePayment}
        onClose={handleCloseSchedulePayment}
        PaperProps={{
          sx: { width: 300, maxWidth: '100%', border: 'none', overflow: '', marginTop: '0em', height: '100vh', background: 'rgba(249, 250, 251, 1)' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2, background: 'rgba(249, 250, 251, 1)' }} className="">
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Schedule Payment
            <p className="mb-0">
              <small style={{ fontWeight: '400', color: 'rgba(75, 85, 99, 1)', fontSize: '12px' }}>You are now scheduling a payment</small>
            </p>
          </Typography>
          <IconButton className="d-inline d-sm-none" onClick={() => setSchedulePayment(false)}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 2, }}>
            <div
              className={`modal_content`}
            // style={{overflowY: 'scroll', height: '450px'}}
            >
              {/* <div className="w-100 mb-3">
                <label htmlFor="ounit">Payment Date</label>
                <div
                  className="w-100 payment_date"
                  style={{ position: "relative" }}
                >
                  <span
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "5px",
                      zIndex: "10",
                    }}
                  >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.66602 1.6665V4.1665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M13.334 1.6665V4.1665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M2.91602 7.5752H17.0827" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M13.0781 11.4167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M13.0781 13.9167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.99607 11.4167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.99607 13.9167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M6.91209 11.4167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M6.91209 13.9167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                  </span>
                  <DatePicker
                    selected={paymentDate}
                    onChange={(date) => setPaymentDate(date)}
                    dateFormat="do MMMM yyyy"
                    className="form-control border rounded-0 w-100"
                    placeholderText="Select Payment Date"
                    required
                    fullWidth
                    minDate={new Date()}

                  />
                </div>
              </div> */}

              <div className={`wallet_modal_header m-0 w-100 mb-0`}>
                <label htmlFor="ounit">Payment Time</label>

                <div className=" d-flex">
                  <div className="" style={{ width: "100%" }}>
                    <div
                      className="mb-2 w-100 custom_short_datepkr bg-white"
                      style={{ position: "relative" }}
                    >
                      <span
                        className="bg-white"
                        style={{
                          position: "absolute",
                          left: "9px",
                          top: "5px",
                          zIndex: "20",
                          // transform: 'scale(0.85)'
                        }}
                      >
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.66699 2.1665V4.6665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M13.333 2.1665V4.6665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M2.91699 8.0752H17.0837" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M17.5 7.58317V14.6665C17.5 17.1665 16.25 18.8332 13.3333 18.8332H6.66667C3.75 18.8332 2.5 17.1665 2.5 14.6665V7.58317C2.5 5.08317 3.75 3.4165 6.66667 3.4165H13.3333C16.25 3.4165 17.5 5.08317 17.5 7.58317Z" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M13.0791 11.9167H13.0866" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M13.0791 14.4167H13.0866" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9.99607 11.9167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9.99607 14.4167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6.91209 11.9167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6.91209 14.4167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>

                      {/* <div className="p-2 border rounded-0 pl-5">
                          <p className="mb-0">2:00 PM</p>
                        </div> */}

                      <input
                        aria-label="Time"
                        onChange={handleTimeChange}
                        value={paymentTime}
                        className="form-control border"
                        type="time"
                        defaultValue={defaultTime}
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="freq">Frequency</label>
                  <div className="" style={{ width: "100%" }}>
                    <div className="position-relative bg-white">
                      <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z" fill="#6B7280" />
                        </svg>
                      </span>
                      <Select
                        labelId="freq"
                        id="freq"
                        fullWidth
                        size="small"
                        required
                        name="freq"
                        defaultValue="weekly"
                        value={scheduledOption}
                        onChange={handleScheduleChange}
                        className=""
                      >
                        <MenuItem value="DAILY">Daily</MenuItem>
                        <MenuItem value="WEEKLY">Weekly</MenuItem>
                        <MenuItem value="MONTHLY">Monthly</MenuItem>
                        <MenuItem value="CUSTOM">Custom</MenuItem>
                      </Select>
                    </div>
                  </div>
                </div>
                {scheduledOption === 'CUSTOM' && (
                  <>

                    <div className="mb-3">
                      <label htmlFor="freq">Interval</label>
                      <div className="" style={{ width: "100%" }}>
                        <div className="position-relative bg-white">
                          <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z" fill="#6B7280" />
                            </svg>
                          </span>
                          <Select
                            labelId="freq"
                            id="freq"
                            fullWidth
                            size="small"
                            required
                            name="interval"
                            // defaultValue="every_2_weeks"
                            className=""
                            onChange={handleInterval}
                            value={interval}
                          >
                            <MenuItem value="EVERY_5_DAYS">
                              Every 5 Days
                            </MenuItem>
                            <MenuItem value="EVERY_2_WEEKS">
                              Every 2 Weeks
                            </MenuItem>
                            <MenuItem value="EVERY_2_MONTHS">
                              Every 2 Months
                            </MenuItem>
                            <MenuItem value="BI_ANNUALLY">
                              Bi-Annually
                            </MenuItem>
                            <MenuItem value="QUARTERLY">Quarterly</MenuItem>
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="">Number of Occurence</label>
                      <div className="" style={{ width: "100%" }}>
                        <div className="">
                          <TextField
                            name=""
                            // multiline
                            // rows={4}
                            required
                            type="number"
                            id="ounit"
                            size="small"
                            placeholder=""
                            onChange={handleNoOfTimesChange}
                            fullWidth
                            value={noOfTimes}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <label htmlFor="ounit">Next payment is on</label>

                <div className=" d-flex">
                  <div className="" style={{ width: "100%" }}>
                    <div
                      className="mb-3 w-100 custom_short_datepkr"
                      style={{ position: "relative" }}
                    >

                      <div className="p-2 border rounded-0 ">
                        <p className="mb-0">
                        {format(paymentDate, "do MMMM yyyy - h:mmaaa")}
                        </p>
                      </div>

                      {/* <DatePicker
                                        // showIcon
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                        placeholderText="Start date"
                                        required
                                    /> */}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="mt-2 modal_content"
                style={{ maxHeight: "70vh", overflowY: "scroll" }}
              >
                <div className="d-flex flex-wrap mb-5" style={{ gap: '10px' }}>
                  <button type="button" onClick={verifyScheduleTrue} className="btn btn-primary"><span className="text-white font-weight-normal">Done</span></button>
                  <button type="button" onClick={() => setSchedulePayment(false)} className="btn border bg-white"><span>Cancel</span></button>
                </div>
              </div>
            </div>
          </Stack>
          {/* <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={<Iconify icon="ic:round-clear-all" />}
            >
              Clear All
            </Button>
          </Box> */}
        </Scrollbar>

      </Drawer>
    </>
  );
}
